import React from "react"
import { useRouter } from "next/router"
import { useI18n } from "next-localization"

import { useGoogleSignIn } from "@app/features/download/hooks"
import { Button } from "@app/ui/button"
import { FormatSizeWithUnits } from "@app/ui/format-size-with-units"

import styles from "./google-sign-in-button.module.scss"

const GoogleSignInButton = ({ type }) => {
  const { googleSignIn } = useGoogleSignIn()
  const i18n = useI18n()
  const router = useRouter()
  const textDownload = type === "video" ? i18n.t("common.downloadVideo") : i18n.t("common.downloadPhotos")
  const size = router.query.size !== "null" ? parseFloat(router.query.size as string) : null

  React.useEffect(() => {
    document.body.classList.add(`theme-${router.query.theme}`)
  }, [router])

  return (
    <Button className={styles["button"]} onClick={googleSignIn}>
      {textDownload}
      {size !== null && (
        <span className={styles["weight"]}>
          &nbsp;(
          <FormatSizeWithUnits size={size} />)
        </span>
      )}
    </Button>
  )
}

export { GoogleSignInButton }
